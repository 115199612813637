<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12 sm12>
        <v-card outlined class="primary--border">
          <v-card-title class="title">
            Penalty / Rebate Setup
            <v-spacer></v-spacer>

            <v-btn-toggle v-if="$auth.can('discount-and-penalty-create')" dense>
              <v-btn
                :disabled="parseInt(additionalRowCount) > 5"
                @click="addNewRow"
              >
                Add
              </v-btn>
              <input
                v-model="additionalRowCount"
                type="text"
                style="width:60px;"
                :class="{
                  label_input_field: true,
                  error_x: parseInt(additionalRowCount) > 5,
                }"
              />

              <v-btn
                color="warning"
                :disabled="parseInt(additionalRowCount) > 5"
                @click="addNewRow"
              >
                More {{ parseInt(additionalRowCount) > 1 ? "Rows" : "Row" }}
              </v-btn>
            </v-btn-toggle>
          </v-card-title>
          <v-data-table
            :server-items-length="dataList.length"
            hide-default-footer
            class="penalty_table"
            :headers="headers"
            :items="dataList"
          >
            <template v-slot:item="{ index, item }">
              <tr :key="index">
                <td style="max-width: 28px;">
                  <v-select
                    @change="updateButton = true"
                    v-model="item.type"
                    :items="types"
                    height="30"
                    dense
                    outlined
                  ></v-select>
                </td>
                <td>
                  <v-text-field
                    @keyup="validateData(), (updateButton = true)"
                    :error="
                      item.delay_from_day.length > 0 &&
                        parseInt(item.delay_from_day) < 1
                    "
                    v-model="item.delay_from_day"
                    type="number"
                    outlined
                    dense
                  ></v-text-field>
                </td>

                <td>
                  <v-text-field
                    @keyup="validateData(), (updateButton = true)"
                    :error="
                      item.delay_to_day.length > 0 &&
                        parseInt(item.delay_to_day) < 1
                    "
                    v-model="item.delay_to_day"
                    type="number"
                    outlined
                    dense
                  ></v-text-field>
                </td>
                <td>
                  <v-text-field
                    @keyup="validateData(), (updateButton = true)"
                    :error="item.amount.length > 0 && parseInt(item.amount) < 1"
                    v-model="item.amount"
                    type="number"
                    outlined
                    dense
                  ></v-text-field>
                </td>
                <td style="max-width: 28px;">
                  <v-select
                    @change="updateButton = true"
                    v-model="item.rate_type"
                    :items="rate_types"
                    height="30"
                    dense
                    outlined
                  ></v-select>
                </td>
                <td style="max-width: 28px;">
                  <v-select
                    @change="updateButton = true"
                    v-model="item.month"
                    :items="months"
                    height="30"
                    dense
                    outlined
                  ></v-select>
                </td>
                <td>
                  <delete-button
                    permission="discount-and-penalty-delete"
                    @agree="deleteRecord({ index, id: item.id })"
                  />
                </td>
              </tr>
            </template>
          </v-data-table>
          <edit-button
            @agree="saveRecord"
            icon="dd"
            edit-text="Save Record"
            :disabled="
              dataList.length < 1 ||
                !isAllValid ||
                saving ||
                updateButton === false
            "
            :outlined="false"
            :large="true"
            :message="`Are you sure you want save all the records`"
            :block="true"
            color="primary"
            :label="`Save All Records`"
            permission="discount-and-penalty-create"
          />
        </v-card>
      </v-flex>
    </v-layout>
    <v-overlay :value="saving">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-container>
</template>
<script>
import { mapState } from "vuex";
import { genHeaders } from "../../../../library/helpers";
import { MONTHS_JSON } from "@/config";
// import Form from "@/library/Form";
// import Mixins from "@/library/Mixins";

export default {
  //   mixins: [Mixins],
  data: () => ({
    updateButton: false,
    saving: false,
    additionalRowCount: 1,
    headers: genHeaders([
      "Type,180",
      "Delay Days From,130",
      "Delay Days To,130",
      "Amount,200",
      "Rate Type,180",
      "Month",
      "Action,100",
    ]),
    types: [
      { text: "Penalty", value: "penalty" },
      { text: "Discount", value: "discount", disabled: true },
    ],
    rate_types: [
      { text: "Percentage", value: "percentage" },
      { text: "Flat", value: "flat" },
    ],
    dataList: [],
    isAllValid: false,
  }),
  computed: {
    ...mapState(["batch"]),
    months() {
      let months = [{ text: "All", value: "all" }];
      MONTHS_JSON.map((item) => {
        months.push({ ...item, disabled: true });
      });
      return months;
    },
  },
  mounted() {
    this.get();
  },
  watch: {},
  methods: {
    saveRecord() {
      this.saving = true;
      this.$rest
        .post("/api/discount-penalty", {
          data: this.dataList,
        })
        .then((res) => {
          this.updateButton = false;
          this.$events.fire("notification", {
            message: res.data.message,
            status: "success",
          });
          this.get();
        })
        .finally(() => {
          this.saving = false;
        });
    },

    get() {
      this.$rest.get("/api/discount-penalty").then((res) => {
        this.dataList = res.data.data;
        if (this.dataList.length < 1) {
          this.addNewRow();
        }
        this.validateData();
      });
    },
    addNewRow() {
      [...Array(parseInt(this.additionalRowCount))].map(() => {
        this.dataList.push({
          type: "penalty",
          delay_from_day: "",
          delay_to_day: "",
          amount: "",
          rate_type: "percentage",
          month: "all",
        });
      });
      this.validateData();
    },
    deleteRecord({ id, index }) {
      if (id) {
        this.saving = true;
        this.$rest
          .delete("/api/discount-penalty/" + id)
          .then((res) => {
            this.$events.fire("notification", {
              message: res.data.message,
              status: "success",
            });
            this.get();
            this.validateData();
          })
          .finally((err) => {
            this.saving = false;
          });
      } else {
        this.dataList.splice(index, 1);
      }
    },
    validateData() {
      let validData = 0;

      try {
        this.dataList.map((item) => {
          if (
            item.amount.length < 1 ||
            item.delay_from_day.length < 1 ||
            item.delay_to_day.length < 1 ||
            parseInt(item.amount) < 0 ||
            parseInt(item.delay_from_day) < 0 ||
            parseInt(item.delay_to_day) < 0
          ) {
            throw Error("Here");
          } else {
            validData++;
          }
        });
      } catch (e) {}
      if (validData === this.dataList.length) {
        this.isAllValid = true;
      } else {
        this.isAllValid = false;
      }
    },
  },
};
</script>
<style lang="scss">
.label_input_field {
  border-top: 1px solid rgba(0, 0, 0, 0.12) !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
  border-right: 1px solid rgba(0, 0, 0, 0.12) !important;
  text-align: center;
  &.error_x {
    color: red;
  }
  &:focus {
    outline: none;
  }
}

.v-btn-toggle > .v-btn.v-btn--active {
  color: white !important;
}
.penalty_table td {
  padding-top: 10px;
  padding-bottom: 10px;
  .v-text-field.v-text-field--enclosed {
    margin-bottom: 0;
  }
  //   .v-select.v-input--dense .v-select__selection--comma {
  //     margin: 34px 4px 3px 0;
  //   }
}
</style>
